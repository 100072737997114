import 'lazysizes';

//
export function productsCaruselSlider(epic_teg, time, btn_enable) {
    const carousel = document.querySelector(`${epic_teg} .wrap-item`);
    let prevButton, nextButton;
    if (btn_enable) {
        prevButton = document.querySelector(`${epic_teg} .owl-prev`);
        nextButton = document.querySelector(`${epic_teg} .owl-next`);
    }
    const itemsContainer = document.querySelector(`${epic_teg} .owl-wrapper`);
    const itemWidth = document.querySelector(`${epic_teg} .owl-item`).offsetWidth;
    let currentPosition = 0;
    let intervalId;
    // Функция для прокрутки карусели влево
    function slideLeft() {
        currentPosition -= itemWidth;
        if (currentPosition < -(itemsContainer.offsetWidth - carousel.offsetWidth)) {
            currentPosition = 0;
        }
        itemsContainer.style.transform = `translateX(${currentPosition}px)`;
    }
    // Функция для прокрутки карусели вправо
    function slideRight() {
        currentPosition += itemWidth;
        if (currentPosition > 0) {
            currentPosition = -(itemsContainer.offsetWidth - carousel.offsetWidth);
        }
        itemsContainer.style.transform = `translateX(${currentPosition}px)`;
    }
    // Функция для автоматической прокрутки карусели
    function startCarousel() {
        intervalId = setInterval(slideLeft, time);
    }
    // Запускаем автоматическую прокрутку карусели
    startCarousel();
    // Обработчики событий для кнопок управления
    if (prevButton) {
        prevButton.addEventListener('click', function () {
            clearInterval(intervalId); // Останавливаем автоматическую прокрутку
            slideRight();
            startCarousel(); // Запускаем её заново после прокрутки
        });
    }
    if (nextButton) {
        nextButton.addEventListener('click', function () {
            clearInterval(intervalId); // Останавливаем автоматическую прокрутку
            slideLeft();
            startCarousel(); // Запускаем её заново после прокрутки
        });
    }
    // Останавливаем автоматическую прокрутку при наведении мыши на слайдер
    itemsContainer.addEventListener('mouseover', ()=>{
        clearInterval(intervalId); 
    })

    // Возобновляем автоматическую прокрутку, когда курсор мыши уходит со слайдера
    itemsContainer.addEventListener('mouseout', ()=>{
        startCarousel();  
    })
};
//
function collapse_spoiler(teg) {
    let element = document.querySelector(teg);
    let arrow_after = element.parentElement.children[0];
    if (element.style.display == 'none') {
        element.style.display = 'block';
        arrow_after.classList.add('collapsed');
    } else {
        element.style.display = 'none';
        arrow_after.classList.remove('collapsed');
    }
}
//*****Функции для работы с всплывающими окнами(fansybox)*****//
//Вставляем содержимое одного блока(block2) в другой(block1), перед этим очищаем содержимое принимающего блока (block1)
function innerBlock(block1, block2) {
    block1.innerHTML = '';
    block1.appendChild(block2.cloneNode(true));
}
//Создаем разметку всплывающего окна (fancybox) и вставляем в нее элемент для показа 
// inner - блок, который хотим показать во всплывающем окне
// class_name - имя класса этого блока ('preview' - для  фото компонента preview , 'characteristics-block' - для пояснения технических характерисик, 'buy-modal'- для корзины)
// is_arrows -  признак наличия стрелок в окне
// Пример: createFancybox(document.querySelector('#cart-modal').cloneNode(true), 'buy-modal');
export function createFancybox(inner, class_name, is_arrows = false) {
    const fancybox = document.createElement('div');
    fancybox.style.cssText += 'width: auto;height: auto;display: block; opacity:0;transition: opacity 0.3s;';
    fancybox.classList = (`fancybox-overlay fancybox-overlay-fixed ${class_name}`);
    fancybox.innerHTML = is_arrows ?
        '<div class="fancybox-wrap fancybox-desktop   fancybox-opened" onclick="event.stopPropagation();" style="opacity:0; transition: opacity 0.6s;"> <div class = "fancybox-skin"><div class = "fancybox-outer"><div class = "fancybox-inner"></div> <button class = "fancybox-nav fancybox-prev"><span style = "background-color:transparent;"></span> </button><button  class = "fancybox-nav fancybox-next"><span style = "background-color:transparent;"></span></button></div><button class = "fancybox-item fancybox-close" style = "background-color:transparent;border: none;"></button></div></div>' :
        '<div class="fancybox-wrap fancybox-desktop   fancybox-opened" onclick="event.stopPropagation();" style="opacity:0; transition: opacity 0.6s;"> <div class = "fancybox-skin"><div class = "fancybox-outer"><div class = "fancybox-inner"></div></div><button class = "fancybox-item fancybox-close" style = "background-color:transparent;border: none;"></button></div></div>';

    inner.style.display = 'block';
    const wrap = fancybox.querySelector('.fancybox-wrap');
    fancybox.querySelector('.fancybox-inner').appendChild(inner);
    document.querySelector('body').appendChild(fancybox);

    if (class_name == 'preview') {
        inner.querySelector('.show').onload = function () {
            inner.style.width = this.width;
            inner.style.hight = this.height;
            defineWrapPosition(wrap, fancybox);
            setTimeout(() => {
                fancybox.style.opacity = '1';
            }, 300);
            setTimeout(() => {
                wrap.style.opacity = '1';
            }, 600);
        }
    } else {
        defineWrapPosition(wrap, fancybox);
        setTimeout(() => {
            fancybox.style.opacity = '1';
        }, 200);
        setTimeout(() => {
            wrap.style.opacity = '1';
        }, 300);
    }

    if (is_arrows) {

        const nextBtn = fancybox.querySelector('.fancybox-next');
        const prevBtn = fancybox.querySelector('.fancybox-prev');
        const images = Array.from(fancybox.querySelectorAll('.fancybox-image'));
        const images_length = images.length;
        let image_to_show_index = images.findIndex(elem => elem.classList.contains('show'));

        nextBtn.addEventListener('click', () => {
            image_to_show_index = image_to_show_index == images_length - 1 ? 0 : image_to_show_index + 1;
            showImage(image_to_show_index);
        })
        prevBtn.addEventListener('click', () => {
            image_to_show_index = image_to_show_index == 0 ? images_length - 1 : image_to_show_index - 1;
            showImage(image_to_show_index);
        })

        function showImage(image_to_show_index) {
            images.forEach((image, index) => index == image_to_show_index ? image.classList.add('show') : image
                .classList.remove('show'));
            defineWrapPosition(wrap, fancybox);
        }
    }

    window.addEventListener('resize', () => defineWrapPosition(wrap, fancybox));
    fancybox.addEventListener('click', () => destroyFancybox(fancybox));
    fancybox.querySelector('.fancybox-close').addEventListener('click', () => destroyFancybox(fancybox));
}
//
function defineWrapPosition(wrap, fancybox) {
    wrap.style.left = `${fancybox.offsetWidth / 2 - wrap.offsetWidth / 2}px`;
    wrap.style.top = fancybox.offsetHeight / 2 - wrap.offsetHeight / 2 > 10 ?
        `${fancybox.offsetHeight / 2 - wrap.offsetHeight / 2}px` : '10px';
}
//
export function destroyFancybox(fancybox) {
    fancybox.style.cssText += 'opacity: 0;visibility: hidden;';
    document.querySelector('body').removeChild(fancybox);
}
// Помогает ввести номер телефона в нужном формате +38 (XXX) XXX-XX-XX 
function inputPhoneHelper(inp, key) {
    if (key) {
        if ('0123456789'.includes(key))
            switch (inp.value.length) {
                case 1:
                    switch (inp.value) {
                        case '3':
                            inp.value = '+3';
                            break;
                        case '8':
                            inp.value = '+38';
                            break;
                        case '0':
                            inp.value = '+38 (0';
                            break;
                        case '7':
                            inp.value = '+38 (7';
                            break;
                        default:
                            if (inp.value != '-' && inp.value != '(' && inp.value != ')') inp.value = '+38 (0' + inp
                                .value;
                            else inp.value = '+38 (0';
                            break;
                    }
                    break;
                case 2:
                    switch (inp.value[1]) {
                        case '0':
                            inp.value = '+38 (0';
                            break;
                        case '3':
                            break;
                        case '8':
                            inp.value = '+38';
                            break;
                        default:
                            inp.value = '+38 (';
                            break;
                    }
                    break;
                case 3:
                    inp.value = '+38 (';
                    break;
                case 4:
                case 5:
                case 6:
                    if (key != '0' && key != '7') inp.value = '+38 (0' + key;
                    break;
                case 8:
                    inp.value = inp.value + ')' + ' ';
                    break;
                case 9:
                    inp.value = inp.value.substring(0, inp.value.length - 1) +')'+ ' ' + inp.value.substring(inp.value.length - 1, inp.value.length);
                    break;
                case 10:
                    inp.value = inp.value.substring(0, inp.value.length - 1) + ' ' + inp.value.substring(inp.value.length - 1, inp.value.length);
                    break;
                case 14:
                case 17:
                    inp.value = inp.value.substring(0, inp.value.length - 1) + '-' + inp.value.substring(inp.value
                        .length - 1, inp.value.length);
                    break;
                case 19:
                    checkPhone(inp);
                    break;
            }
        else {
            switch (key) {
                case '+':
                    inp.value = inp.value.replaceAll('+', '');
                    inp.value = '+' + inp.value;
                    break;
                case '(':
                    inp.value = inp.value.replaceAll('(', '');
                    inp.value = inp.value.substring(0, 4) + '(' + inp.value.substring(4, inp.value.length);
                    break;
                case ')':
                    inp.value = inp.value.replaceAll(')', '');
                    inp.value = inp.value.substring(0, 8) + ')' + ' ' + inp.value.substring(9, inp.value.length);
                    break;
                case '-':
                    inp.value = inp.value.replaceAll('-', '');
                    if (inp.value.length >= 13) inp.value = inp.value.substring(0, 13) + '-' + inp.value.substring(
                        13, inp.value.length);
                    if (inp.value.length >= 16) inp.value = inp.value.substring(0, 16) + '-' + inp.value.substring(
                        16, inp.value.length);
                    break;
                case ' ':
                    inp.value = inp.value.replaceAll(' ', '');
                    if (inp.value.length >= 3) inp.value = inp.value.substring(0, 3) + ' ' + inp.value.substring(3,
                        inp.value.length);
                    if (inp.value.length >= 9) inp.value = inp.value.substring(0, 9) + ' ' + inp.value.substring(9,
                        inp.value.length);
                    break;
                default:
                    inp.value = inp.value.replace(key, '');
                    break;
            }

        }
    }
}
// Проверяет телефонный номер по длине и количеству цифр в нем   
function checkPhone(input, block_id){
    const count_num=input.value.split('').filter(el=>'0123456789'.includes(el)).length;
    if (input.value.length==19 && count_num==12) 
    document.querySelectorAll(`#${block_id} .phone-number-valid`).forEach(elem=>elem.classList.remove('active')); 
    else document.querySelectorAll(`#${block_id} .phone-number-valid`).forEach(elem=>elem.classList.add('active'));
}
//
function formatPhoneNumber(element) {
    element.addEventListener('focus', function (e) {
        if (e.target.value === '') {
            e.target.value = '+38 (0';
        }
    });

    element.addEventListener('input', function (e) {
        let x = e.target.value.replace(/\D/g, ''); // Удаляем все нецифровые символы
        x = x.startsWith('38') ? x.substring(2) : x; // Удаляем префикс '38', если он есть
        x = x.startsWith('0') ? x.substring(1) : x; // Удаляем начальный '0', если он есть
        x = x.substring(0, 9); // Обрезаем до 9 символов

        let formattedValue = '+38 (0';
        if (x.length > 0) {
            formattedValue += x.substring(0, 1); // Добавляем первую цифру после '0'
        }
        if (x.length > 1) {
            formattedValue += x.substring(1, 2) + ') '; // Добавляем следующие три цифры
        }
        if (x.length > 2) {
            formattedValue += x.substring(2, 5); // Добавляем следующие две цифры
        }
        if (x.length > 5) {
            formattedValue += '-' + x.substring(5, 7); // Добавляем следующие две цифры
        }
        if (x.length > 7) {
            formattedValue += '-' + x.substring(7, 9); // Добавляем последние две цифры
        }
        e.target.value = formattedValue; // Применяем отформатированное значение
    });

    element.addEventListener('keydown', function (e) {
        var key = e.keyCode || e.charCode;
        if ((key === 8 || key === 46) && e.target.value === '+38 (0') {
            e.preventDefault(); // Предотвращаем удаление основной части номера
        }
    });
}

window.formatPhoneNumber = formatPhoneNumber;
window.collapse_spoiler = collapse_spoiler;
window.innerBlock = innerBlock;
window.createFancybox = createFancybox;
window.destroyFancybox = destroyFancybox;
window.inputPhoneHelper = inputPhoneHelper;
window.checkPhone = checkPhone;